.product-card .eo-stepper {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 18px;
    padding: 6px;
    display: inline-flex;
    align-items: center;
}

.product-card .eo-stepper button {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    font-size: 30px;
}

.product-card .eo-stepper span {
    color: white;
    min-width: 40px;
    text-align: center;
}
