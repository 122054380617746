.eo-img {
    position: relative;
}

.eo-img::before {
    display: block;
    padding-top: calc(3 / 4 * 100%);
    content: "";
}

.eo-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.eo-img.no-src img {
    visibility: hidden;
}
