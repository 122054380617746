.eo-cart ion-thumbnail {
    --size: 140px;
    --border-radius: 14px;
}

.eo-cart ion-label {
    margin-top: 0;
}

.eo-cart .eo-stepper {
    display: inline-flex;
    align-items: center;
}

.eo-cart .eo-stepper button {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    font-size: 20px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
}

.eo-cart .eo-stepper button:disabled {
    background-color: rgba(var(--ion-color-primary-rgb), 0.5);
}

.eo-cart .eo-stepper span {
    font-weight: bold;
    min-width: 40px;
    text-align: center;
}
