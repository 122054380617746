
ion-list.eo-gray-list {
    --eo-list-padding: 10px;
    background-color: var(--ion-color-light);
    border-radius: 10px;
    padding: 0 var(--eo-list-padding) var(--eo-list-padding);
}

ion-list.eo-gray-list ion-item {
    --background: var(--ion-item-background);
    --border-radius: 10px;
    margin-top: var(--eo-list-padding);
}

.eo-form ion-item {
    --padding-start: 0;
}

.eo-form ion-label[position="stacked"] {
    font-size: unset;
}

.eo-form ion-input,
.eo-form ion-select,
.eo-form ion-textarea {
    background-color: var(--ion-color-light);
    border-radius: 10px;
    --padding-start: 8px !important;
    margin-top: 5px;
}

.eo-form ion-textarea.full-height {
    height: 100%;
    position: relative;
}

.eo-form ion-textarea.full-height textarea {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.eo-cart-sidebar {
    right: 0;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.eo-cart-sidebar ion-segment {
    background-color: white;
}

.eo-cart-sidebar ion-item {
    --background: none;
    --padding-start: 0;
    --inner-padding-end: 0;
    justify-content: start;
    align-content: start;
    align-items: start;
}

.eo-cart-sidebar ion-card ion-item ion-icon {
    margin-right: 7px;
}

.eo-cart-sidebar ion-item.comment {
    --background: var(--ion-color-light);
    --padding-end: 10px;
    --padding-start: 10px;
}

.eo-order-container,
.eo-cart-sidebar .order-container {
    background: #ffffff;
    border: 1px dashed #616e7e;
    border-radius: 5px;
    color: #616e7e;
    padding: 5px;
    flex-grow: 1;
    font-size: 13px;
}
.eo-cart-sidebar .order-container {
    margin-top: 7px;
}

.eo-cart-sidebar .current-order-pickup .customer-container {
    min-height: 4em;
}

.eo-cart-sidebar .cart-body {
    flex-grow: 1;
    margin-bottom: 10px;
}

.eo-cart-sidebar .cart-footer .total-item {
    display: flex;
    justify-content: space-between;
}

.eo-cart-sidebar .cart-footer .total-item-sub {
    color: #616e7e;
    font-size: 13px;
}

.eo-cart-sidebar .cart-footer .total-item-sum {
    margin: 16px 0;
}

.eo-cart-sidebar .cart-table {
    display: table;
}

.eo-cart-sidebar dt {
    display: inline;
    margin-top: 4px;
}

.eo-cart-sidebar dt::before {
    content: "";
    display: block;
    clear: both;
}

.eo-cart-sidebar dd {
    float: right;
    /* margin: 0; */
    display: inline;
    margin-inline-start: 10px;
    text-align: right;
}

.eo-inline-checkbox {
    display: flex;
    align-items: center;
    position: relative;
}

.eo-inline-checkbox ion-checkbox {
    --size: 16px;
    --background-checked: var(--ion-color-medium);
}

.eo-inline-checkbox ion-checkbox::part(container) {
    border-radius: 3px;
    border: 1px solid var(--ion-color-medium);
}
