.products-header {
    display: flex;
    align-items: end;
}

.products-header ion-searchbar {
    --background: var(--ion-item-background);
    padding-bottom: 0;
}

.product-card {
    margin: 0;
}

.product-card ion-card-title {
    margin-top: 10px;
    font-size: 20px;
    height: 2.5em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.product-card .product-card-price {
    margin-top: 16px;
    white-space: nowrap;
}

.product-card .product-card-price span {
    color: black;
}

.eo-cart-sidebar ion-card {
    padding: 7px;
    box-shadow: none;
    margin-inline: unset;
}

.eo-cart-sidebar .cart-body .cart-empty {
    margin: auto;
    text-align: center;
}

.eo-cart-sidebar .cart-body .cart-empty ion-icon {
    width: 60px;
    height: 60px;
}

.search-customer-modal .search-customer-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.search-customer-modal .search-customer-empty {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.search-customer-modal .search-customer-empty ion-icon {
    width: 60px;
    height: 60px;
}

.search-customer-modal ion-searchbar {
    padding: 0 0 6px 0;
}

.eo-form ion-label {
    font-weight: bold;
}

.select-address-modal ion-item {
    font-weight: bold;
}

.checkout-modal .checkout-modal-menu ion-item {
    --min-height: 80px;
}

.checkout-modal .checkout-modal-menu .ion-color-edit {
    color: var(--ion-color-primary);
    font-weight: bold;
}

.change-delivery-modal ion-item {
    --min-height: 60px;
}

.change-delivery-modal ion-label {
    font-weight: bold;
}

.discount-modal ion-item {
    --background: var(--ion-color-light);
    border-radius: 10px;
    --padding-start: 8px;
    --min-height: 60px;
}

.time-date-modal ion-item {
    --min-height: 60px;
}